// _shared.scss
// contains style definitions shared app-wide

.modal-form-group {
  margin-top: 40px;
}

// for app running in a 'test' environment
.env-test {
  .navbar {
    opacity: 0.4 !important;
    border: 0 !important;

    li > a {
      color: white !important;
    }
  }
}
