.navbar {
  .navbar-brand {
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .welcome-msg {
    color: $white;
    font-size: 16px;
    margin-left: 2rem;
  }
}

.navbar-default {
  background-color: $brand-primary;
}

.navbar-default .navbar-nav>li>a:focus, .navbar-default .navbar-nav>li>a:hover {
  color: #CCC;
}

.navbar-default .navbar-nav>li>a {
  color: #FFF;
}

.navbar-nav {
  > li > a {
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 13px;
    margin-bottom: 13px;
    border: 1px solid transparent;

    &.active {
      color: $gray-lighter;
    }
  }
  > li > button {
    padding-top: 9px;
    padding-bottom: 9px;
    margin-top: 13px;
    margin-bottom: 13px;
  }
}
