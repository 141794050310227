
.offers-block {
  margin-top: 3.5rem;
}

.offers-page {
  margin-bottom: 5rem;
}

.offer-card {
  margin-bottom: 2rem;
  border: 1px solid $gray-lighter;
  .row {
    padding: 1.5rem 0;
  }
  .offer-image {
    margin-bottom: 2rem;
  }
  h5 {
    margin: 20px 0;
  }

  li {
    list-style-type: none;
  }

  .action-bar {
    border-top: 1px solid $gray-lighter;
    background-color: $gray-lightest;

    .deal-links {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .button-group {
      display: flex;
      justify-content: flex-end;

      & > *:not(:first-child) {
        margin-left: 2rem;
        @include breakpoint("onlyxs") {
          margin-left: 0;
        }
      }
      @include breakpoint("onlyxs") {
        flex-direction: column;
        & > * {
          margin-top: 2rem;
        }
      }
    }
  }

  .sales-performance {
    &:not(.active) {
      margin: 16px 0;
      background-color: $gray-lightest;
    }
    &.active {
      border-top: 1px solid $gray-lightest
    }
    .sales-performance-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      .glyphicon {
        color: $brand-primary;
        padding-left: 8px;
        padding-right: 8px;
      }
    }
    .table {
      thead {
        background-color: #5E6668;
      }
    }
    .last-updated {
      padding-top: 16px;
    }
  }
}

.schedule-history {
  max-height: 400px;
  overflow-y: scroll;
  .dates {
    display: flex;
    justify-content: center;
  }
}
