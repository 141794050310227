.announcement {
  border: solid 1px #ee7c00;
  background-color: #ffe3c2;
  padding-top: 30px;
  padding-bottom: 0;
  padding-left: 46px;
  padding-right: 46px;
}

.announcement-title {
  font-weight: bold;
  font-size: 20px;
  color: black;
  margin-bottom: 13px;
}

.announcement-row {
  margin-bottom: 0px;
}

.announcement-content {
  font-size: 16px;
  color: black;
  line-height: 1.38;
  letter-spacing: normal;
  margin-bottom: 30px;
}

.announcement-button{
  margin-bottom: 30px;
}
