.react-bs-table-container {
  background-color: #ffffff;

  &.clickable {
    tbody {
      tr {
        cursor: pointer;
      }
    }
  }

  + .btn {
    margin-top: 20px;
  }
}

.react-bs-container-header {
  border: 0;
}

.react-bs-table {
  border-radius: 0;
  margin: 0;

  .table {
    thead {
      background-color: $brand-primary;
      color: $white;
    }

    th,
    td {
      overflow: visible;
      white-space: normal;
      text-overflow: inherit;
      word-wrap: break-word;
    }
  }
}

.react-bs-container-header,
.react-bs-container-body {
  overflow-x: auto;
  min-height: 0.01%; // Workaround for IE9 bug (see https://github.com/twbs/bootstrap/issues/14837)

  @include breakpoint("onlyxs") {
    width: 100%;
    margin-bottom: ($line-height-computed * 0.75);
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid $table-border-color;

    // Tighten up spacing
    > .table {
      margin-bottom: 0;

      // Ensure the content doesn't wrap
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            width: 200px;
          }
        }
      }
    }

    // Special overrides for the bordered tables
    > .table-bordered {
      border: 0;

      // Nuke the appropriate borders so that the parent can handle them
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th:first-child,
          > td:first-child {
            border-left: 0;
          }
          > th:last-child,
          > td:last-child {
            border-right: 0;
          }
        }
        > tr:last-child {
          border-bottom: 0;
        }
      }

      // Only nuke the last row's bottom-border in `tbody` and `tfoot` since
      // chances are there will be only one `tr` in a `thead` and that would
      // remove the border altogether.
      > tbody,
      > tfoot {
        > tr:last-child {
          > th,
          > td {
            border-bottom: 0;
          }
        }
      }

    }
  }
}

.react-bs-table-pagination {
  .row {
    > div > div {
      width: 100%;
      text-align: center;
    }
  }
}

.react-bootstrap-table-page-btns-ul {
  > li > a {
    border: 0;
    font-weight: bold;
  }
  > li.active > a {
    background-color: transparent;
    color: black;
  }
}
