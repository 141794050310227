$increment-count-height: 3.5rem;

.availability-page {
  .roomtypepicker-input {
    border: 1px solid #e0e0e0;
    padding: 13px 17px;
    font-weight: 400;
    text-align: left;
    background: none;
    width: 100%;
  }

  .roomtypepicker-container {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .roomtypepicker-options {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    width: 100%;
    background-color: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }

  .roomtypepicker-options-row {
    border: 1px solid #e0e0e0;
    border-top: none;
    padding: 13px 17px;
    cursor: pointer;
    &:hover {
      background-color: $gray-lighter;
    }
  }

  .calendar-container {
    margin-top: 25px;
  }

  .calendar {
    margin-top: 0;
    padding-top: 0;
    border-top: 0;
    .day {
      &.active {
        box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
      }
      &.blackout {
        color: #bbb;
        text-decoration: line-through;
      }
      &.surcharge {
        .date {
          padding-left: .6em;
        }
      }
    }
    .counts {
      &.dirty {
        color: $brand-primary;
        font-weight: 400;
      }
      &.low-inventory {
        color: $brand-warning;
      }
    }
  }

  .increment-count-input {
    button {
      border: 1px solid #e0e0e0;
      width: 100%;
      height: $increment-count-height;
      background: inherit;
      padding-top: 1px;
    }
    padding: 5px;
    margin-top: 5rem;
    border-top: none;
    position: absolute;
    z-index: 1000;
    background: white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    width: 100%;
    text-align: center;
  }

  .action-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 3rem 0;
    @include breakpoint("onlyxs") {
      flex-direction: column;
      .button-group {
        margin-top: 2rem;
      }
    }
    .button-group {
      display: flex;
      justify-content: space-between;
      & > *:not(:first-child) {
        margin-left: 2rem;
      }
    }
  }
}