@import './variables';
@import './vendor/vendor';
@import './site';
@import './shared';

//== Tools
//
//##
@import './tools/layout';
@import './tools/responsive';
@import './tools/typography';

//== Components
//
//##
@import './components/Buttons';
@import './components/Header';
@import './components/Panels';
@import './components/Tables';
@import './components/Modal';
@import './components/Calendar';
@import './components/Availability';
@import './components/DateTimePicker';
@import './components/Profile';
@import './components/Offers';
@import './components/Orders';
@import './components/Remittance';
@import './components/Upload';
@import './components/Traveller';
@import './components/Announcement';
@import './components/DatesRequests';

html {
  font-weight: $default-font-weight;
}

body {
  overflow-y: scroll;
}

.container {
  width: 100%;
  max-width: 1500px;
}
