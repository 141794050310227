//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:      "Roboto", "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-size-base:              16px;


//== Navbar
//
//##

// Basics of a navbar
$navbar-height:                    68px;
$navbar-inverse-link-color:        $gray-light;

$nav-pills-border-radius:          0;
$nav-link-hover-bg:                transparent;

//== Iconography
//
//##

$bootstrap-sass-asset-helper: false;
$icon-font-path:              "//maxcdn.bootstrapcdn.com/bootstrap/3.3.6/fonts/";

$panel-heading-padding:       17px 15px;
$panel-default-heading-bg:            #fff;

//== Buttons
//
//##
$btn-border-radius-base:         0;
$btn-border-radius-large:        0;
$btn-border-radius-small:        0;

//== Forms
//
//##
$input-border-radius:            0;
$input-border-radius-large:      0;
$input-border-radius-small:      0;

//== Tables
//
//##
//** Background color used for `.table-hover`.
$table-bg-hover:                $gray-lighter;

//== Popovers
//
//##
$border-radius-large: 0;
$popover-title-bg: #F3F7FC;
