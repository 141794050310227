.profile-page {
  .section {
    background-color: $gray-lightest;
    margin: 2rem 0;
    padding: 4rem;
  }
}

.login-page {
  .forgot-password {
    margin-left: 2rem;
  }
}