.orders-page {
  .headings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    h3 {
      margin-top: 11px;
    }
  }
  .search-bar {
    margin: 15px 5px 10px;
    display: flex;
    flex-direction: row;
    .form-group {
      margin-bottom: 0;
      flex: 1;
    }
  }
  .action-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    justify-content: space-between;
    & > *:not(:first-child) {
      margin-left: 2rem;
    }
    @include breakpoint("onlyxs") {
      flex-direction: column;
      & > * {
        margin-top: 2rem;
      }
    }
  }

  .button-content {
    display: flex;
    flex-direction: row;
    & > *:not(:first-child) {
      margin-left: 1rem;
    }
  }
}
