.dates-requests {
  .offer-id {
    display: flex;
    flex-wrap: wrap;
    word-break: break-all;
    .link {
      font-size: 14px;
      margin-left: 4px;
    }
  }
}
.dates-request-reject {
  .modal-dialog {
    width: 650px;
    max-width: 90%;
    .textarea-container {
      max-height: 43px;
      transition: max-height 0.25s ease-out;
      overflow: hidden;
      &.show-textarea {
        max-height: 130px;
        transition: max-height 0.25s ease-in;
      }
    }
    .modal-body {
      text-align: left;
    }
  }
  .form-group {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    label {
      font-weight: 500;
      &.radio-inline {
        font-weight: 300;
      }
    }
    & > * {
      margin-left: 0;
    }
  }
  .help-block {
    font-size: 14px;
  }
  .btn-dates-request.btn {
    margin-left: 8px;
    margin-bottom: 8px;
  }
}