//== Margin Helpers
//
//##

.nm {
  margin: 0;
}

.nmt {
  margin-top: 0;
}

.nmr {
  margin-right: 0;
}

.nmb {
  margin-bottom: 0;
}

.nml {
  margin-left: 0;
}

.ma {
  margin: auto;
}

//== Padding Helpers
//
//##

.np {
  padding: 0;
}

.npt {
  padding-top: 0;
}

.npr {
  padding-right: 0;
}

.npb {
  padding-bottom: 0;
}

.npl {
  padding-left: 0;
}

.spx {
  &.s10 {
    margin-left: 10px;
    margin-right: 10px;
  }
}
