$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%); // #222
$gray-dark:              lighten($gray-base, 20%);   // #333
$gray:                   lighten($gray-base, 33.5%); // #555
$gray-light:             lighten($gray-base, 46.7%); // #777
$gray-lighter:           lighten($gray-base, 93.5%); // #eee
$gray-lightest:          #f9f9f9;

$brand-primary:         #4298CF;
$brand-success:         #5cb85c;
$brand-info:            #5bc0de;
$brand-warning:         #f0ad4e;
$brand-danger:          #d9534f;
$brand-muted:           $gray-light;

$white:                 #FFF;


$title-highligts:       #FF4200;
$title-edit-link:       $brand-info;
$ui-gray:               #D8D8D8;
$text-gray:             $gray;
$text-active-gray:      $gray-dark;
$completed-state-color: $brand-primary;

$default-font-weight:   300;


// Buttons
$padding-large-horizontal: 4.5rem;
$font-size-large:          14px;


$btn-primary-color:       $white;
$btn-primary-bg:          $brand-primary;
$btn-primary-border:      $brand-primary;

$btn-default-color:       $brand-primary;
$btn-default-bg:          $gray-lighter;
