.modal-dialog {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0) !important;
  padding-bottom: 30px;
}

.modal-header {
  background-color: $white;
  .floating-close-button {
    height: 3rem;
    width: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: $gray-lighter;
    position: absolute;
    right: -1.5rem;
    top: -1.5rem;
  }
}

.modal-body {
  text-align: center;
}

.traveller-modal {
  text-align: left !important;
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.modal-footer {
  text-align: center;
}

.modal-title {
  font-size: 2em;
}

.modal-desc {
  margin-top: 8px;
}

.actions {
  text-align: right;

  .action ~ .action {
    margin-left: 10px;
  }
}

.change-date-confirm-modal {
  .date-range {
    display: inline-block;
    margin-left: 12px;
    font-weight: 500;
    vertical-align: middle;
  }
  
  .modal-body {
    text-align:left;
  }
}

.traveller-details-modal {
  width: 700px;
}