.spinner-container {
  display: flex;
  align-items: center;
  justify-content: center;
  &.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}

.spinner {
  margin-left: auto;
  margin-right: auto;
  display: block;
  z-index: 99999;
}

.offer-edit-form img {
  max-width: 100%;
}

.glyphicon {
  color: $brand-primary;
}

.btn {
  .glyphicon {
    padding-right: 6px;
  }
}

.btn-primary {
  .glyphicon {
    color: $white;
  }
}

.btn-link {
  .glyphicon {
    color: $ui-gray;
    &.primary {
      color: $brand-primary;
    }
  }
}

button:disabled {
  .glyphicon {
    color: $ui-gray;
  }
}

.subtext {
  color: #9A9B9B;
}

.image-list-panel {
  .panel-body {
    padding: 0;
  }

  .image-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.modal-content {
  border: 0;
}

.modal-header {
  background-color: #F3F7FC;
  border-bottom: 0;

  h4 {
    font-weight: bold;
  }
}

.modal-footer {
  border-top: 0;
}

.icon-button {
  color: inherit;
  opacity: 0.5;

  &:hover, &:active {
    opacity: 1;
  }

  &:hover, &:active, &:visited, &:focus {
    color: inherit;
  }
}

.icon {
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
}

.adminUserRolesTab {
  ul {
    border: 0px !important;
  }
  a {
    border: 0px !important;
  }
  .active {
    font-weight: bold;
  }

}

ul.actions {
  list-style-type: none;
  padding: 3px 0 0 0;
  margin: 0;
  -webkit-padding-start: 0;
}

li.actions {
  width: 100%;
  padding-bottom: 3px;
}

a.actions {
  width: 100%;
  display: block;
}

.action-group {
  display: inline-flex;
}

.action-item {
  margin: 5px;
  flex-wrap: nowrap;
}

.text-large {
  font-size: 22px;
}

.space-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.glyphicon-color-green {
  color: #21d37e;
}

.glyphicon-color-yellow {
  color: #f5d22b;
}