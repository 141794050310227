@mixin breakpoint($width) {
  @if $width == sm {
    @media (min-width: $screen-sm-min) { @content;}
  }
  @else if $width == md {
    @media (min-width: $screen-md-min) { @content; }
  }
   @else if $width == lg {
    @media (min-width: $screen-lg-min) { @content; }
  }
  //extends to only
  @else if $width == onlyxs {
    @media (max-width: $screen-xs-max) {@content;}
  }
  @else if $width == onlymb {
    @media (min-width: 0) and (max-width: $screen-sm-min - 1) {@content;}
  }
  @else if $width == onlysm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {@content;}
  }
  @else if $width == onlymd {
    @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {@content;}
  }
  @else {
    @media (min-width: $width) { @content; }
  }
}
