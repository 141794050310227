$calendar-cell-height:  5.8rem;

.inline-group {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex: 1 1 auto;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.availability-calendar {
  position: relative;
  margin: 1rem auto;
  border: 1px solid #ddd;

  .buttons {
    // position: absolute;
    display: flex;
    width: 100%;
    height: 5.8rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    button {
      border: 1px solid #d6d9da;
      border-radius: 3px;
      background-color: #fff;
      padding: 0.5rem 0.9375rem;

      &:disabled {
        border-color: #ebeced;
        background-color: #f9f9f9;
        color: #d6d9da;
      }
    }
  }
}

.calendar {
  background: white;
  display: flex;
  flex-direction: column;
  align-self: center;
  margin: 1rem auto;
  border: 1px solid #ddd;
  position: relative;
  padding: 10px 0;

  .month-name {
    display: flex;
    height: 5.8rem;
    align-items: center;
    justify-content: center;
    font-weight: bold;
  }

  .week {
    display: flex;

    &.names {
      border-top: 1px solid #ecefef;
    }
  }

  .day-name {
    display: flex;
    flex-grow: 1;
    width: 6.4rem;
    height: 4rem;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
  }

  .buttons {
    position: absolute;
    display: flex;
    width: 100%;
    height: 5.8rem;
    align-items: center;
    justify-content: space-between;
    padding-left: 15px;
    padding-right: 15px;

    button {
      border: 1px solid #d6d9da;
      border-radius: 3px;
      background-color: #fff;
      padding: 0.5rem 0.9375rem;

      &:disabled {
        border-color: #ebeced;
        background-color: #f9f9f9;
        color: #d6d9da;
      }
    }
  }

  .day {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: inherit;
    background: none;
    border: 0;
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;

    &.blocked {
      color: #999999;
    }

    &.check-in {
      color: #ffffff;
      background-color: #00587c;
    }

    &.check-out {
      color: #004663;
      background-color: #ccedef;
    }

    &.selected {
      color: #ffffff;
      background-color: #00a3ad;
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .day-container {
    display: flex;
    flex-grow: 1;
    color: #004663;
    flex-shrink: 0;
    width: 6.4rem;
    height: $calendar-cell-height;
    position: relative;
  }

  .date {
    font-weight: 400;
  }

  .counts {
    margin-top: 2px;
    font-size: 80%;
    line-height: 1em;
  }
}
